<template>
  <v-dialog width="400" v-model="dialog">
    <v-card id="pedirReservaLink">
      <v-card-title
        style="text-decoration: underline; text-transform: uppercase"
      >
        Link pedir reserva:
      </v-card-title>
      <v-card-subtitle class="mt-0 text-center primary--text">
        {{ $t("box_reservation") }}
      </v-card-subtitle>
      <div class="close">
        <v-btn icon @click="dialog = false">
          <v-icon id="iconClose">mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-text-field
          outlined
          dense
          id="URL"
          hide-details
          readonly
          v-model="URL"
        >
          <template slot="prepend-inner">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon class="mr-2" v-on="on" @click="copy()">
                  mdi-content-copy
                </v-icon>
              </template>
              {{ $t("copy") }}
            </v-tooltip>
          </template>
        </v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          style="color: #363533; height: 25px; padding-top: 10px !important"
          elevation="0"
          type="submit"
          @click="send"
          class="ml-0"
          ><v-icon style="margin-right: 5px" size="14px">$send</v-icon>
          {{ $t("send", { name: "" }) }}
        </v-btn>
        <SendMail ref="sendMail" hidden :url="URL" :special="true" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "BoxLink",
  data() {
    return {
      dialog: false,
      accion: "send",
    };
  },
  methods: {
    copy() {
      /* Get the text field */
      var copyText = document.getElementById("URL");

      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /*For mobile devices*/

      /* Copy the text inside the text field */
      document.execCommand("copy");
      this.resDialog = false;
      this.$alert(this.$t("link_copy_success"), "", "");
    },

    send() {
      this.$refs.sendMail.open("PAGA Y SEÑAL");
    },
  },
  watch: {},
  components: {
    NumberInputWithSeparators: () =>
      import("@/components/ui/NumberInputWithSeparators"),
    SendMail: () => import("@/components/mails/SendMail"),
  },
  computed: {
    URL() {
      if (this.$store.getters["auth/isStudio"])
        return `${window.location.origin}/?#/inscription/studio/${this.$store.state.auth.user.studio.token}`;
    },
  },
};
</script>

<style lang="sass" scoped>
label
  display: block
  height: 25px
</style>
